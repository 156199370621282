import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import TwoImageSection from "../../components/dynamic-sections/TwoImageSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"
import KeynoteSection from "../../components/dynamic-sections/KeynoteSection"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"
import VideoIcon from "../../../assets/video-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ProjectEvePage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/labs/project-eve/",
          name: "EVE Manufacturing | Norhart",
          image: `${config.siteMetadata.siteUrl}/labs/norhart-project-eve-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="EVE Manufacturing | Norhart"
      description="At Norhart, we believe innovation is crucial to making exceptional apartments in Forest Lake, Blaine, and Oakdale, MN. At Norhart's top-secret warehouse EVE, we bring our vision and ideas to life."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/labs/project-eve/norhart-project-eve-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/labs/project-eve/norhart-project-eve-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="EVE Manufacturing"
        subtitle="The Black Box Where Our Apartments Become Reality"
        imageTitle="Norhart Project EVE"
        image="/labs/project-eve/norhart-project-eve-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Innovative Manufacturing"
        tagLine="Building the future of tomorrow today"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TwoImageSection
        backgroundColorOne="#E5E8e9"
        backgroundColorTwo="#EFF2F4"
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Innovation"
        title="Norhart's Project EVE"
        subtitle="At Norhart, we believe innovation is crucial to making exceptional apartments and making people's lives better! In our Top Secret Warehouse (Area 651) we bring our vision and ideas to life."
        imageTitleOne="Creating a Better Way To Live"
        imageOneText={`Our creed is, "At Norhart, our focus is to create the exceptional. We build more than just apartments and units. We love to change people's lives". These are not mere words; they represent our company values and what we strive to achieve.`}
        imageOne="/labs/project-eve/norhart-project-eve-better-way-to-live.webp"
        imageOneAlt="Norhart Creating a Better Way To Live"
        imageOnePill=""
        imageTitleTwo="Total Industry Disruption"
        imageTwoText="Our leadership team and employees at Norhart will not stop until we disrupt and dominate the multi-home living industry! We don't want to be just the best; we want to define our industry and set the standard. #Disruption"
        imageTwo="/labs/project-eve/norhart-project-eve-industry-disruption.webp"
        imageTwoAlt="Norhart Total Industry Disruption"
        imageTwoPill=""
        pillActive={false}
        colorPalette={colorPalette}
        buttonName=""
        buttonUrl=""
      />

      <KeynoteSection
        backgroundColorOne="#1e4c80"
        backgroundColorTwo="#1e4c80"
        icon={VideoIcon}
        iconTitle="Watch Video"
        title="EVE Sneak Peek"
        subtitle="A sneak peek of Norhart's innovative manufacturing plant, EVE. This secret black box is where our dreams become a reality!"
        colorPalette={colorPalette}
        posterFrame=""
        keynoteUrl="/labs/project-eve/video/norhart-eve.mp4"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="We Build Award Winning Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ProjectEvePage
